@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: sans-serif;
  }

  * {
    box-sizing: border-box;
  }
}

.mdx a {
  text-decoration: underline;
}

@layer components {
  input[type="checkbox"]:checked ~ label span svg {
    @apply inline-flex;
  }

  .center-column {
    @apply w-full md:max-w-centerColumnMD xl:max-w-centerColumnXL 2xl:max-w-centerColumn2XL 3xl:max-w-centerColumn3XL;
  }

  .sticky .header-logo {
    @apply py-5 lg:py-2;
  }

  .sticky .menu-scroll.active {
    @apply opacity-70;
  }

  input#togglePlan:checked ~ .dot {
    @apply translate-x-full;
  }

  input#checkboxLabel:checked ~ .box span {
    @apply opacity-100;
  }
}

code {
  line-break: anywhere;
}
